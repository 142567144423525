body.saf{
    background: $saf3;

    #header_bottom{
        background-color: $saf1;
    }
    #navigation {
        ul {
            li { 
                a {
                    &.selected{
                        background: $saf3;
                        color: $saf-font1;
                    }
                    &:hover{
                        background: $white;
                        color: $bip-font1;
                    }
                }
            }
        }
    }
    #logo{
        background: $saf2;
    }
    footer{
        background-color: $saf1;
    }
    .content_item p a, .content_item p a:link, .content_item p a:active, .content_item p a:visited,
    a.content-link, a.content-link:link, a.content-link:active, a.content-link:visited {
        color: $saf-font1;
        text-decoration: none; 
    }
    .content_item p a:hover, a.content-link:before{
        color: $saf2;
    }
}