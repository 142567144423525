@media(max-width:1140px){
    .st6{
        display: none;
    }
    .col1{
        width: 6%;
    }
    .col2{
        width: 11%;
    }
    .col3{
        width: 17%;
    }
    .col4{
        width: 23%;
    }
    .col5{
        width: 29%;
    }
    .col9{
        width: 35%;
    }
}
@media(max-width:1040px){
    .st5{
        display: none;
    }
    .col1{
        width: 7%;
    }
    .col2{
        width: 14%;
    }
    .col3{
        width: 22%;
    }
    .col4{
        width: 28%;
    }
    .col5{
        width: 37%;
    }
    .col9{
        width: 38%;
    }
}
@media(max-width:600px){
    .st4{
        display: none;
    }
    .col1{
        width: 11%;
    }
    .col2{
        width: 21%;
    }
    .col3{
        width: 28%;
    }
    .col4{
        width: 35%;
    }
    .col5{
        width: 55%;
    }
    .col9{
        width: 36%;
    }
}
@media(max-width:450px){
    .st3{
        display: none;
    }
    .col1{
        width: 12%;
    }
    .col2{
        width: 32%;
    }
    .col3{
        width: 47%;
    }
    .col4{
        width: 48%;
    }
    .col5{
        width: 70%;
    }
    .col9{
        width: 37%;
    }
}



@media(max-width:800px){
    .ce9{
        width: 100%;
    }
}
@media(max-width:450px){
    .ce6{
        width: 100%;
    }
    .ce12{
        width: 100%;
    }
    #content_mein h2{
        margin-left:0;
    }
}


/*@media(max-width:1240px){*/
@media(max-width:1380px){
    #page{
        margin:20px;
    }
    #mein_inner{
        margin:20px;
    }
    #header_top {
        padding: 0 20px;
    }
    #header_bottom {
        padding: 0 20px;
    }
    .detailbox .col9{
        width: 49%;
    }
    .detailbox .col10{
        width: 46%;
    }
}
/*@media(max-width:1110px){*/
@media(max-width:1250px){
    #content .filterbox{
        display: none;
    }
    #content aside{
        float: none;
        width: 100%;
        min-height: 50px;
    }
    #plattformtitle{
        display: none;
    }
    .info{
        display: none;
    }
    .filtertoggle{
        display: block;
    }
    aside .box {
        text-align: center;
    }
    aside .statistics{
        display: none;
    }
    aside .crosslinks{
        display: none;
    }
    .ml3 {
        margin-left: 0;
    }
    aside.empt{
        display: none;
    }
    aside .button._wide{
        width: 250px;
    }
    #mein_right{
        width: 160px;
        display: block;
        position: relative;
        margin: 20px 0 20px 0;;
    }
    #content_mein{
        margin-right: 0;
    }    
}
/*@media(max-width:950px){*/
@media(max-width:1090px){
    #partner{
        display: none;
    }
    #content{
        margin-right: 0;
    }
    #header_top_container{
        right:0;
    }
    #header_top {
        padding: 0;
    }
}
/*@media(max-width:930px){*/
@media(max-width:1070px){
    #content_mein aside{
        float: none;
        width: auto;
        margin: 0;
    }
    #content_mein aside ul{
        margin: 0 200px 0 0;
    }
    #content_mein aside ul ul{
        margin: 0;
    }
    #mein_right{
        display: block;
        position: absolute;
    }
}
@media(max-width:900px){
    #logo{
        left: 0;
    }
}

@media(max-width:820px){
    #footer_inner{
        margin: 20px;
    }
}
@media(max-width:800px){
    #content_mein .ce8, #content_mein .ce9{
        display: block;
        float: none;
        width: 100%;
    }
    #content_mein .ce9{
        margin-top: 20px;
    }
}
@media(max-width:780px){
    #menubutton {
        display: block;
    }
}
@media(max-width:770px){
    .detailcol{
        float: none;
        width: 100%;
    }
    .detailbox .col9, .detailbox .col10{
        width: 100%;
    }
    .detailbox .col10{
        margin-top: 20px;
    }
    .overlaybox.size3{
        margin: 20px;
    }
}
@media(max-width:730px){
    .overlaybox.size3{
        margin: 20px;
    }
}
@media(max-width:680px){
    .tiles .item:nth-of-type(3n+4) {
        clear: none;
    }
    .tiles .item:nth-of-type(3n+3) {
        margin-right: 2%;
    }
    .tiles .item:nth-of-type(2n+3) {
        clear: left;
    }
    .tiles .item:nth-of-type(2n+2) {
        margin-right: 0;
    }
    .tiles .item {
        width: 49%;
    }
    .tiles .detailbox {
        width: 204%;
    }
    .tiles .item:nth-of-type(3n+3) .detailbox::after {
        left: 23%;
    }
    .tiles .item:nth-of-type(2n+1) .detailbox::after {
        left: 23%;
    }
    .tiles .item:nth-of-type(2n+2) .detailbox::after {
        left: 72%;
    }
    .tiles .item:nth-of-type(3n+3) .detailbox {
        margin-left: 0;
    }
    .tiles .item:nth-of-type(2n+1) .detailbox {
        margin-left: 0;
    }    
    .tiles .item:nth-of-type(2n+2) .detailbox {
        margin-left: -104%;
    }

}
@media(max-width:600px){
    #page{
        margin:10px;
    }
    .rd-hide1{
        display: none;
    }
    #mein_inner{
        margin:10px;
    }
}
@media(max-width:520px){
    .overlaybox{
        margin: 20px;
    }
}
@media(max-width:450px){
    .largenotice{
        width: 90%;
    }
}
@media(max-width:430px){
    #content_mein h2.form-title {
        margin-left: 0;
    }
}
@media(max-width:430px){
    .form-row .form-row-label, .form-row .form-row-content{
        display: block;
    }
    .form-row .form-row-label.col7, .form-row .form-row-content.col12{
        width: 100%
    }
    .image-container-s.fr {
        float: none;
    }
    .detailcol .actions{
        overflow: hidden;
    }
    .detailcol .actions a, .detailcol .actions a:visited, a.singleaction, a.singleaction:visited {
        margin-left: 12px;
    }
    .rectangle div{
        position: absolute;
        left: 0;
    }
    #content_mein aside ul{
        margin: 0;
    }
    #mein_right{
        position: relative;
    }
    #mein_right .image-container{
        display: none;
    }
}

@media(max-width:380px){
    .rectangle div{
        position: absolute;
        left: -20px;
    }
    .tiles .item:nth-of-type(1n+2) {
        clear: left;
    }
    .tiles .item:nth-of-type(1n+1) {
        margin-right: 0;
    }
    .tiles .item {
        width: 100%;
    }
    .tiles .detailbox {
        width: 100%;
    }
    .tiles .item:nth-of-type(3n+1) .detailbox {
        margin-left: 0;
    }
    .tiles .item:nth-of-type(3n+2) .detailbox {
        margin-left: 0;
    }
    .tiles .item:nth-of-type(3n+3) .detailbox {
        margin-left: 0;
    }
    .tiles .item:nth-of-type(2n+1) .detailbox {
        margin-left: 0;
    }
    .tiles .item:nth-of-type(2n+2) .detailbox {
        margin-left: 0;
    }
    .tiles .item:nth-of-type(1n+1) .detailbox::after {
        left: 47%;
    }
}


