body {
    #navigation {
        position: absolute;
        bottom: 0;
        left: 200px;
        ul {
            padding: 0 0 0 7px;
            margin:  0;
            &:after{
                clear: both;
                content: " ";
                display: block;
                font-size: 0;
                height: 0;
                visibility: hidden;
            }
            li { 
                list-style: none;
                float:left;
                a,a:link,a:visited,a:active{
                    text-decoration: none;
                    display: block;
                    color: $white;
                    font-weight: 400;
                    padding: 6px 13px 6px 13px;
                    font-size: 20px;
                    &:hover{
                        background: $white;
                    }
                    &.selected{
                        background: $bip3;
                        color: $bip-font1;
                    }
                }
                &:hover{
                    a, a.selected {
                        background: $white;
                        color: $bip-font1;
                        box-shadow: 0 10px 10px #999999;
                        &.selected{
                            background: #fff;
                            /*font-weight: 700;*/
                            box-shadow: none;
                        }
                    }
                    li {
                        a {
                            box-shadow: none;
                        }
                    }
                    > ul {
                        visibility: visible;
                        
                    }
                }
                li {
                    a,a:link,a:visited,a:active{
                        padding: 5px 0 5px 0;
                        font-size: 16px;
                        width: 240px;
                        color: $bip-font1;
                        &.selected{
                            background: $white;
                            font-weight: 700;
                            box-shadow: none;
                            color: $bip-font1;
                        }
                    }
                }
                ul{
                    display: inline;
                    visibility: hidden;
                    position: absolute;
                    padding:10px 0 10px 20px;
                    background: #ffffff;

                    box-shadow: 0 10px 10px #999999;
                    z-index: 1800;
                    li {
                        float: none;
                        a:hover{
                            color: #333;
                        }
                    }
                }
            }
        }
    }
    #footer_nav{
        ul {
            padding: 0;
            margin:  0;
            &:after{
                clear: both;
                content: " ";
                display: block;
                font-size: 0;
                height: 0;
                visibility: hidden;
            }
            li{
                list-style: none;
                float:left;
                li{
                    list-style: none;
                    float:none;
                    a,a:link,a:visited,a:active{
                        padding: 5px;
                        font-size: 14px;
                        color: #ffffff;
                    }
                }
                a,a:link,a:visited,a:active{
                    text-decoration: none;
                    display: block;
                    color: #ffffff;
                    font-weight: 400;
                    padding: 5px;
                    margin-right: 30px;
                    font-size: 20px;
                    &:hover{
                        color: #e5e5e5;
                    }
                }
            }
        }
    }
    #metanavigation {
        float: right;
        ul {
            padding: 0px;
            margin: 0px;
            list-style: none;
            li {
                float: left;
                a {
                    color: #ffffff;
                    font-size: 12px;
                    padding: 8px 0 7px 15px;
                    display: block;
                }
            }
        }
    }
    #plattformnavigation {
        float: right;
        width: 150px;
        margin: 0 0 0 30px;
        ul {
            padding: 0px;
            margin: 0px;
            list-style: none;
            li {
                float: left;
                a {
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    display: block;
                }
            }
        }
    }
}
@media(max-width:930px){
    body {
        #nav_mein{
            float: none;
        }
    }
}
@media(max-width:900px){
    body {
        #navigation{
            left:100px;
        }
    }
}
@media(max-width:820px){
    body {
        #metanavigation{
            display: none;
        }
    }
}
@media(max-width:780px){
    body {
        #navigation{
            bottom: initial;
            top:70px;
            z-index: 999;
            background: #fff;
            /*width: 100%;*/
            left: initial;
            right: 0;
            box-shadow: 0 10px 10px #999999;
            -moz-box-shadow: 0 10px 10px #999999;
            -webkit-box-shadow: 0 10px 10px #999999;
            display: none;
            padding-top: 10px;
        }
        #navigation ul {
            padding: 0;
        }
        #navigation ul:after, #footer_nav ul:after {
            clear: none;
        }
        #navigation ul li{
            list-style: none;
            float:none;
            display: block;
        }
        #navigation ul li a, #navigation ul li a:link, #navigation ul li a:visited, #navigation ul li a:active {
            text-decoration: none;
            display: block;
            background: #ffffff;
            font-weight: 400;
            padding: 6px 13px 6px 13px;
            font-size: 20px;
            color: #333;
        }
        #navigation ul li a.selected {
            background: #fff;
        }
        #navigation ul li ul{
            display: block;
            visibility:visible;
            position: relative;
            padding:0 10px 10px 10px;
            background: #ffffff;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
        }
        #navigation ul li li a, #navigation ul li li a:link, #navigation ul li li a:visited, #navigation ul li li a:active {
            padding: 6px 13px 6px 23px;
        }
        #navigation ul li li a:hover {
            background: #e5e5e5;
        }
        #footer_nav ul li{
            float: none;
            margin-bottom: 20px;
        }
        #footer_nav ul li li{
            margin-left: 20px;
            margin-bottom: 5px;
        }
    }
}
@media(max-width:600px){
    body {
        #plattformnavigation{
            display: none;
        }
    }
}

@media(max-width:430px){
    body {
        #footer_nav, #footer_meta{
            float: none;
            text-align: center;
            margin-bottom: 20px;
        }
        #footer_nav ul li a, #footer_nav ul li a:link, #footer_nav ul li a:visited, #footer_nav ul li a:active,#footer_meta ul li a, #footer_meta ul li a:link, #footer_meta ul li a:visited, #footer_meta ul li a:active{
            margin-right: 0;
        }
        #footer_nav ul li li{
            margin-left:0;
        }
    }
}
