$bip1:      #cccccc;
$bip2:      #999999;
$bip3:      #e5e5e5;
$bip-font1: #666666;

$umt1:      #66cc66;
$umt2:      #669966;
$umt3:      #dbe7db;
$umt-font1: #336633;

$ste1:      #66ccff;
$ste2:      #4090b9;
$ste3:      #dfe9ee;
$ste-font1: #0b577e;

$swb1:      #f1654c;
$swb2:      #c3272b;
$swb3:      #f2e5e3;
$swb-font1: #841a1d;

$saf1:      #ffb61e;
$saf2:      #e08a1e;
$saf3:      #f5eddd;
$saf-font1: #6f440e;

$white:     #ffffff;
$font:      #333333;
$font-nav:  #666666;