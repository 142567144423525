.detail-table{
    i {
        width: 30px;
        display: inline-block;
        font-style: normal;
        float: left;
        &.large{
            width: 140px;
        }
    }
    b{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 80%;
        max-width: calc(100% - 35px);
    }
    &.inverted{
        i {
            width: auto;
            float: none;
        }
        b{
            float: left;
            margin-right: 15px;
        }
    }
}
.map-detail-container{
    background: #ccc;
    width: 100%;
    height: 300px;
    margin-bottom: 40px;
    
    .bigmaplink{
        display: block;
        text-align: right;
        margin-top: 5px;
        
        &:before{
            content: '\f35d';
            font-family: 'Font Awesome Solid';
            font-size: 12px;
            margin: 0 5px;
            color: #333;
        }
    }
    
    .map-detail-container-inner{
        width: 100%;
        height: 100%;
    }
}
.fotocontainer{
    overflow: hidden;
    margin-bottom: 20px;
    
    .image-box{
        &.multi {
            width: 120px;
            margin: 0 10px 0 0;
        }
        .image-container-m{
            width: 115px;
        }
    }
}