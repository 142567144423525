#template_preview_org_logo_container,
#template_preview_job_logo_container{
    display: none;
}

#template_preview_job_title{
    h1{
        font-size: 16px;
        font-weight: 400;
        margin: 0 0 15px;
    }
}

#template_preview_extra_info{
    .row{
        display: flex;
        
        .col-md-3{
            flex: 30% 0 0;
        }
        .col-md-9{
            flex-grow: 2;
        }
    }
}