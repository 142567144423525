
*{
    margin: 0;
    padding: 0;
}
html{
    min-height: 100%;
}
body{
    font-family: 'Roboto', sans-serif;
    color: #333333;
    font-size: 14px;
    background: #e5e5e5;
    font-weight: 400;
    min-height: 100%;
    letter-spacing: 0.05em;
    font-weight: 300;
}
header {
    background-color: #cccccc;
}
/*h1{
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 5px 20px;
    height: 30px;
}
h2{
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
h3{
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}*/
p {
    margin: 0 0 10px 0;
}
b {
    font-weight: 700;
}
#header_top{
    background: #333333;
    height: 30px;
}
#header_top_inner{
    max-width: 1340px;
    margin:auto;
    position: relative;
}
#header_top_container{
    overflow: hidden;
    right:140px;
    position: absolute;
}
#header_bottom{
    height: 70px;
}
#header_bottom_inner{
    position: relative;
    max-width: 1340px;
    margin:auto;
    height: 70px;
}
#logo{
    width: 100px;
    height: 100px;
    position: absolute;
    left: 80px;
    bottom: 0;
    color: #ffffff;
    font-size: 90px;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
    font-weight: 700;
    background: #999999;
}
#authentication{
    height: 30px;
    overflow: hidden;
    float: right;
    margin: 0 0 0 30px;
}
#mein{
    display: none;
    overflow: hidden;
    background: #555555;
    padding-bottom: 30px;
    /*position: absolute;
    z-index: 999;*/
}
#mein_inner{
    margin: 30px auto;
    max-width: 1340px;
    overflow: hidden;
    position: relative;
    
}
#nav_mein {
    float: left;
    margin: 35px 20px 0 0;
    min-height: 300px;
    width: 180px;
}
#mein_right {
    width: 300px;
    right: 0;
    top:0;
    margin: 35px 0 0 20px;
    background: none;
    height: auto;
    position: absolute
}
#content_mein{
    max-width: 1020px;
    overflow: hidden;
    margin-right: 320px;
}

#content_mein aside{
    float: left;
    width: 180px;
    min-height: 300px;
    margin: 35px 20px 0 0;
}
#content_mein > .box{
    max-height: 600px;
    overflow: auto;
}
#mein ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
#mein ul ul{
    display: none;
}
#mein ul li{
    margin: 0;
    padding: 0;
    list-style: none;
}
#mein ul li li{
    margin: 0 0 0 27px;
    padding: 0;
    list-style: none;
}
#mein ul li a{
    padding: 5px 10px;
    margin-bottom: 5px;
    background: #fff;
    display: block;
    font-weight: 400;
    overflow: hidden;
    cursor: pointer;
}
#mein ul li a.selected,#mein ul li a:hover{
    background: #e5e5e5;
}
#mein ul li a span{
    display: inline-block;
    width: 27px;
    height: 27px;
    float: left;
    margin: -5px 10px -5px -10px;
}
#mein_profildaten{
    color: #FFFFFF;
    margin: 20px 0 20px 0;
}
#globalsearch{
    height: 30px;
    overflow: hidden;
    float: left;
    margin: 0 0 0 200px;
}
a.button,a.button:visited,a.button:link,a.button:active,a.button2,a.button2:visited,a.button2:link,a.button2:active{
    display:inline-block;
    background: #555555;
    color: #ffffff;
    font-size:15px;
    padding: 6px 15px;
    text-transform: uppercase;
    cursor: pointer;
}
a.button span,a.button2 span{
    margin: 0 6px 0 10px;
}
a.button:hover{
    background: #999999;
}
a.button2,a.button2:visited,a.button2:link,a.button2:active{
    background: #999999;
}
a.button2:hover{
    background: #e5e5e5;
    color: #333333;
}
a.button.pad2{
    padding: 6px;
}
a.button._wide{
    width: calc(100% - 12px);
    text-align: center;
}
a, a:link, a:active, a:visited{
    color: #333333;
    text-decoration: none; 
}
a:hover{
    color: #333333;
    text-decoration: none; 
}

/*/////////////// Content-Structure //////////////////////////////////*/

#page{
    max-width: 1340px;
    margin: 20px auto;
    overflow: hidden;
    position: relative;
}
#partner{
    width: 300px;
    right: 0;
    top:0;
    margin: 35px 0 0 20px;
    background: none;
    height: auto;
    position: absolute
}
#buttons{
    background: #ffffff;
    width: 160px;
    height: 120px;
    overflow: hidden;
}
#buttons_inner{
    background: #ffffff;
    width: 1000px;
    height: 120px;
}
.partner_item{
    padding: 10px;
    text-align: center;
    width: 140px;
    height: 40px;
}
.partner_container{
    width: 160px;
    height: 190px;
    overflow: hidden;
    display: inline-block;
}
#skyscraper{
    /*background: #ffffff;*/
    width: 300px;
    height: 600px;
    margin: 20px 0 0 0;
}
#skyscraper > div{
    /*margin: auto;*/
}
.rectangle{
    height:250px; 
    width:300px;
}
#content{
    max-width: 1045px;
    overflow: hidden;
    margin-right: 320px;
}
#content aside{
    float: left;
    width: 180px;
    min-height: 300px;
    margin: 0 20px 0 0;
}
#content aside.wide{
    width: 250px;
}
#content main{
    min-height: 895px;
    overflow: hidden;
}
#content .titlebox, #content_mein .titlebox{
    height: 30px;
    margin: 0 0 5px 0;
    overflow: hidden;
}
#content aside .titlebox h1{
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 5px 0 0 20px;
    vertical-align: bottom;
}
#content main ,
#content_mein {
    .titlebox{
        h1 {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            margin: 0 0 0 20px;
            float: left;
        }
        &.detailview {
            h1 {
                text-transform: none;
            }
        }
    }
}
#content_mein .titlebox h1{
    margin: 0;
}
#content_mein .titlebox {
    color: #fff;
}
#content_mein .tiles .item {
    width: 246px;
}
#content main h2 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 15px 0;
}
#content main h3, #content_mein h3{
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 15px 0;
}
#content main h3.mt, #content_mein h3.mt{
    margin-top: 30px;
}
#content main h3.nm, #content_mein h3.nm{
    margin: 0;
}
#content_mein h2{
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 15px 0;
    text-transform: uppercase;
}
#content_mein h2.form-title{
    margin-left: 33%;  
}
#content_mein h2.mt{
    margin-top: 30px;
}
#content main .downloads, #content_mein .downloads{
    margin: 15px 0 0 0;
}
#content main .downloads a,#content_mein .downloads a{
    position: relative;
    margin-right: 15px;
    display: inline-block;
    text-align: center;
    height: 110px;
}
#content main .downloads a i.bigicon,#content_mein .downloads a i.bigicon{
    font-size: 65px;
    margin-bottom: 15px;
}
#content main .downloads a i.bigicon-ol,#content_mein .downloads a i.bigicon-ol{
    font-size: 30px;
    position: absolute;
    bottom: 30px;
    right: -10px;
    color: #66cc66;
}
#content main .downloads a span,#content_mein .downloads a span{
    font-size: 10px;
}
footer{
    background: #ccc;
    overflow: hidden;
    min-height: 200px;
}
#footer_inner{
    max-width: 800px;
    margin: 20px auto;
}
.content_item{
    border-bottom: dashed #ccc 1px;
    padding: 15px 0;
    overflow: hidden;
}
.content_item p{
    line-height: 20px;
}
.content_item p a, .content_item p a:link, .content_item p a:active, .content_item p a:visited,
a.content-link, a.content-link:link, a.content-link:active, a.content-link:visited{
    color: #000000;
    text-decoration: none; 
}
.content_item p a:before, a.content-link:before{
    content: '\f35d';
    font-family: 'Font Awesome Solid';
    font-size: 12px;
    margin: 0 5px;
    color: #333;
}
.content_item p a:hover{
    color: #333;
}
.partner_content{
    background: #e5e5e5;
    padding: 20px;
}
.content_image{
    width: 35%;
    float:left;
    margin: 0 20px 10px 0;
}
.content_image2{
    width: 100%;
    margin: 20px 0 10px 0;
}
.content_image3{
    width: 50%;
    float:left;
    margin: 0 20px 10px 0;
}

.content_date{
    float: right;
}
.team{
    display: block;
    width: 240px;
    height: 420px;
    float: left;
    background: #fff;
    margin: 0 30px 50px 0;
    position: relative;
}
.team:nth-of-type(3n+3) {
    margin-right: 0;
}
.table .item_inner > span:nth-child(2){
    margin-left: 5px;
}
.box{
    background: #ffffff;
    padding: 20px;
    overflow: hidden;
}
.nav-umt, .betr-ptf>.umt{
    background-color: #66cc66;
}
.nav-ste, .betr-ptf>.ste{
    background-color: #66ccff;
}
.nav-swb, .betr-ptf>.swb{
    background-color: #f1654c;
}
.nav-saf, .betr-ptf>.saf{
    background-color: #FFB61E;
}
.nav-bip, .betr-ptf>.bip{
    background-color: #cccccc;
}
.box{
    ul {
        margin: 0;
        padding: 0 0 0 20px;
    }
}
/*///////////////////////// ITEMS ////////////////////////*/
.table .item{
    display: block;
    width:100%;
    /*overflow: hidden;*/
    border-top: #e5e5e5 solid 1px;
    position: relative;
}
.table .item_inner{
    cursor: pointer;
    overflow: hidden;
    padding-left: 10px;
}
.table .item_inner.inactive{
    background: #cccccc;
    .weekoverview i {
        background: #999;
        &.active{
            background: #6cf;
        }
    }
}

.table .item_inner.deleted{
    background: #f8eeee;
}
.table .item_inner.inactive .table-icon, .table .item_inner.deleted .table-icon{
    color: #ffc100;
}
.table .item:first-child{
    border-top: none;
}
.table .item_inner:hover{
    background: #ededed;
}
.table .item_inner > span{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top:5px;
    margin-bottom:5px;
    /*margin-right: 0.5%;*/
}
.table .item_inner > span.wrap{
    white-space: normal;
}
.table .item_inner > span > label{
    display: none;
}
.table .item_inner > span.status_text{
    font-size: 10px;
    background: #edd3d3;
    padding: 5px;
    white-space: normal;
    text-align: left;
    margin: 0 5px 5px 5px;
    display: block;
}
.table .item_inner > span:nth-child(2){
    margin-left: 5px;
}
#content_mein_inner .table .item_inner > span:nth-child(2){
    margin-left: 0;
}
#content_mein_inner .table .item_inner > span:nth-child(1){
    margin-left: 5px;
}
.table .item .image,
.table .item .detailheader,
.table .item .detailfooter,
.table .item .detailoverlay {
    display: none;
}
.table .item .listimage {
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    display: inline-block;
    float: left;
    margin-right: 10px;
}
.table .item .small {
    font-size: 10px;
}

.tiles{
    overflow: hidden;
    position: relative;
}
.tiles .item{
    display: block;
    width: 32%;
    float: left;
    background: #fff;
    margin: 0 2% 2% 0;
    position: relative;
}
.tiles .item:nth-of-type(3n+3){
    margin-right: 0;
}
.tiles .item_inner{
    overflow: hidden;
    height: 250px;
    border: solid #ccc 1px;
    position: relative;
    cursor: pointer;
}
.tiles .item span{
    display: none;
}
.tiles .item span.admbar{
    display: block;
    background: rgba(255, 255, 255, 0.8);
    height: 30px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
}
.tiles .item span.thumbnail{
    display: inline-block;
}
.tiles .item_inner.inactive span.admbar{
    background: rgba(150, 150, 150, 0.8);
}
.tiles .item_inner.deleted span.admbar{
    background: rgba(237, 211, 211, 0.8);
}


.tiles .item .image{
    display:block;
    width: 100%;
    height: 250px;
    background-position: center top;
    background-size:cover;
    background-repeat: no-repeat;
    background-color: #fff;
}
.tiles .item .detailheader{
    display:block;
    width: 100%;
    width: calc(100% - 20px);
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}
.tiles .item .detailfooter{
    display:block;
    width: 100%;
    width: calc(100% - 20px);
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    text-align: right;
    
    .sjh{
        height: 30px;
    }
}
#content main .tiles .item .detailheader h3{
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    text-shadow: 0px 0px 6px rgba(20, 20, 20, 1);
}
.tiles .item .detailheader .canton{
    float: right;
    height: 40px;
    width: 30px;
}

.tiles .item .detailoverlay{
    height: auto;
    min-height: 20px;
    position: absolute;
    bottom: 0;
    background: #e5e5e5;
    display: none;
    padding: 10px;
    width: calc(100% - 20px);
    -webkit-box-shadow: 0px -5px 20px 0px rgba(102,102,102,0.34);
    -moz-box-shadow: 0px -5px 20px 0px rgba(102,102,102,0.34);
    box-shadow: 0px -5px 20px 0px rgba(102,102,102,0.34);
    color: #333;
    font-size: 12px;
}

.tiles .item .detailheader span,
.tiles .item .detailoverlay span{
    display:block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.tiles .item .detailheader span label,
.tiles .item .detailoverlay span label{
    display:inline-block;
    width: 40%;
}
.tiles .item .detailheader span div,
.tiles .item .detailoverlay span div{
    display:inline-block;
}
.tiles .item:nth-of-type(3n+4) {
    clear: left;
}
.detailbox{
    background: #f5f5f5;
/*    width: 780px;*/
    position: relative;
    margin-top: 20px;
}
.tiles .detailbox{
    width: 313%;
}
.detailbox .box{
    background: transparent;
}
.table .detailbox .box.inner{
    background: #ffffff;
}
.box.inner.inactive{
    background: #e5e5e5;
}
.box .box.inner{
    background: #f7f7f7;
}
.box .box.inner.inactive{
    background: #e5e5e5;
}
.box .box.inner .table-icon, #content_mein_inner .box .table-icon{
    float: right;
    font-size: 23px;
    color: #ffc100;
    cursor: pointer;
}
.box .box.inner .table-icon.red, #content_mein_inner .box .table-icon.red{
    color: #c00;
}
.box .box.inner .table-icon.listmarker, #content_mein_inner .box .table-icon.listmarker{
    font-size: 16px;
    float: none;
}
#content_mein_inner .box .table-icon{
    color: #666666;
}
.detailbox .loader, .overlaybox .loader{
    background: #fff;
    text-align: center;
}
#overlay_inner .loader{
    text-align: center;
}
.detailbox::after {
    border-bottom: 15px solid #f5f5f5;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    content: "";
    display: inline-block !important;
    position: absolute;
    left: 48%;
    top: -15px;
}
.tiles .item:nth-of-type(3n+2) .detailbox {
    margin-left: -106%;
}
.tiles .item:nth-of-type(3n+3) .detailbox {
    margin-left: -212%;
}

.tiles .item:nth-of-type(3n+1) .detailbox::after {
    left: 15%;
}
.tiles .item:nth-of-type(3n+3) .detailbox::after {
    left: 81%;
}

.table .weekoverview{
    width: 40px;
    white-space: normal;
    line-height: 7px;
}
.table .weekoverview i{
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 1px 1px 0;
    background: #cccccc;
}
.table .weekoverview i.active{
    background: #66ccff;
}

.detailcol .weekoverview, #dv_tooltip .weekoverview{
    width: 100%;
    white-space: normal;
    line-height: 20px;
    box-shadow: none;
}
.detailcol .weekoverview i, #dv_tooltip .weekoverview i{
    display: inline-block;
    width: 17%;
    height:45px;
    margin: 0 1% 1% 0;
    background: #cccccc;
}
.detailcol .box.inner .weekoverview i{
    display: inline-block;
    width: 17%;
    height:40px;
    margin: 0 1% 1%s 0;
    background: #cccccc;
}
.detailcol .weekoverview i:nth-of-type(5n+5), #dv_tooltip .weekoverview i:nth-of-type(5n+5){
    margin-right: 0;
}
.detailcol .weekoverview i.active, #dv_tooltip .weekoverview i.active, .detailcol .box.inner .weekoverview i.active{
    background: #66ccff;
}
.detailcol .weekoverview i.header, #dv_tooltip .weekoverview i.header {
    background: none;
    text-align: center;
    vertical-align: middle;
    height: 18px;
    font-style: normal;
}
.detailcol .box.inner .weekoverview i.header {
    background: none;
    text-align: center;
    vertical-align: middle;
    height: 18px;
    font-style: normal;
}
.table .availability{
    width: 360px;
    height: 20px;
    overflow: hidden;
    background: #cccccc;
    position: relative;
}
.table .availability a{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #4090b9;
    position: absolute;
}
.table .availability i.active{
    background: #66ccff;
}
.col1{
    width: 5%;
}
.col2{
    width: 10%;
}
.col3{
    width: 15%;
}
.col4{
    width: 20%;
}
.col5{
    width: 25%;
}
.col6{
    width: 30%;
}
.col7{
    width: 35%;
}
.col8{
    width: 40%;
}
.col9{
    width: 45%;
}
.col10{
    width: 50%;
}
.col12{
    width: 60%;
}
.col14{
    width: 70%;
}
.col16{
    width: 80%;
}
.col20{
    width: 100%;
}
.ce3{
    width: 12%;
}
.ce4{
    width: 22%;
}
.ce5{
    width: 28%;
}
.ce6{
    width: 33%;
}
.ce8{
    width: 45%;
}
.ce9{
    width: 50%;
}
.ce12{
    width: 66%;
}
.w15{
    width: 15%;
}
.w85{
    width: 85%;
}

.table-icon{
    color: #cccccc;
    font-size: 16px;
}
.credits{
    position: relative;
    font-style: normal;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: #ffc100;
    font-size: 25px;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    width: 22px;
    
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.credits::before{
    font-family:'Font Awesome Solid';
    font-style: normal;
    content:"\f111";
    margin-left: -5px;
}
.credits::after {
    color: #fff;
    content: "C";
    font-family: "Roboto",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-left: -19px;
    padding-bottom: 4px;
    vertical-align: middle;
}
.moneys{
    position: relative;
    font-style: normal;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: #13810a;
    font-size: 25px;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    width: 22px;
    
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.moneys::before{
    font-family:'Font Awesome Solid';
    font-style: normal;
    content:"\f111";
    margin-left: -5px;
}
.moneys::after {
    color: #fff;
    content: "M";
    font-family: "Roboto",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin-left: -20px;
    margin-top: 1px;
    padding-bottom: 4px;
    vertical-align: middle;
}
.nr_row label{
    color: #999999;
    display: block;
    font-size: 12px;
}
.nr_row p{
    font-size: 20px;
    background: #efefef;
    text-align: center;
    padding: 10px;
    display: block;
}
.rating-box {
    position:relative;
    vertical-align: middle;
    font-size: 10px;
    font-family: Font Awesome Solid;
    display:inline-block;
    color: #e5e5e5;
}
.rating-box:before{
        content: "\f005 \f005 \f005 \f005 \f005";
    }

.rating {
    position: absolute;
    left:0;
    top:0;
    white-space:nowrap;
    overflow:hidden;
    color: #ffbc04;
}
.rating:before {
    content: "\f005 \f005 \f005 \f005 \f005";
}
.tiles .item .detailoverlay .rating-box {
    color: #ccc;
}
.niveau {
    font-size: 12px;
    color: #e5e5e5;
}
.niveau .active1{
    color: #fdcf25;
}
.niveau .active2{
    color: #db701f
}
.niveau .active3{
    color: #cc0000;
}
.niveau i{
    position: relative;
    margin-left: 1px;
}
.niveau i span{
    position: absolute;
    z-index: 100;
    color: #fff;
    font-size: 10px;
}
#overlay{
    display: none;
}
#overlay_bg{
	display:none;
	position:absolute;
	background:#666666;
	z-index:1000;
	width:100%;
	height:100%;
	top:0;
	left:0;
}
#overlay{
	display:none;
	position:absolute;
	z-index:1001;
	width:100%;
	height:100%;
	top:0;
	left:0;
}
#overlay_inner{
	margin:100px auto 0 auto;
}
.overlaybox{
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    max-width: 400px;
    min-height: 200px;
    margin: 0 auto 0 auto;    
    position: relative;
}
.overlaybox.size2{
    max-width: 500px;
}
.overlaybox.size3{
    max-width: 650px;
}
.overlaybox h1{
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 20px 0;
}
.overlay_loading{
    margin: auto;
}
.overlay_submit{
	position: absolute;
    bottom:20px;
    right: 20px;
}
.inputmeta{
    background: #555555;
    border:0;
    padding: 3px;
    margin: 3px 0 0 0;
    color: #ffffff;
}
.detailcol{
    float: left;
    overflow: hidden;
}
#content{
    h2.bereich {
        margin-bottom: 5px;
        margin-top: 20px;
        &:first-of-type{
            margin-top: 0;
        }
    }
}
.themarow{
    overflow: hidden;
    padding: 6px;
    &:hover{
        background: #e5e5e5;
    }
    a {
        display: block;
        width: 80%;
        width: calc(100% - 60px);
        font-weight: 400;
    }
    span{
        width: 50px;
        float: right;
        text-align: right;
    }
}
.detailrow{
    overflow: hidden;
    margin-bottom: 10px;
}
.detailrow.small{
    font-size: 12px;
}
.detailrow .detailrow-label{
    display: inline-block;
    float: left;
    font-size: 12px;
    color: #999999;
}
.detailrow .detailrow-content{
    display: inline-block;
    .sjh{
        width: 100%;
        margin: 10px 0;
    }
}
.detailcol {
    .preview {
        width: 99%;
        border: 1px solid #cccccc;
        background: #fff;
        margin-bottom: 20px;
        &.init-galery{
            cursor: pointer;
        }
    }
}
.detailcol .rating-box, .detailcol .niveau{
    font-size: 20px;
}
.detailcol .actions{
    min-height: 39px;
    text-align: right;
}
.detailcol .actions a, .detailcol .actions a:visited, a.singleaction, a.singleaction:visited{
    font-size: 25px;
    color: #999;
    margin-left: 20px;
    cursor: pointer;
}
.detailcol .actions a:hover{
    color: #cccccc;
}
.detailcol .actions a.selected{
    color: #66cc66;
}
.detailcol .thumbnails{
    overflow: hidden;
    margin: 0 0 20px 0;
    text-align: right;
}
.detailcol .thumbnail{
    display: inline-block;
    margin: 0 1.1% 0 0;
    width: 19%;
    height: 75px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
}
.detailcol .thumbnail:nth-child(5n+5){
    margin: 0;
}
.swb_logo {
    height: 100px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    display: block;
}

/*//////////////////////// FORMS ////////////////////////*/
.filterbox {
    margin: 0 0 10px 0;
    background: #ffffff;
    padding: 10px 15px;
    overflow: hidden;
}
.filtertitle{
    font-weight: 700;
    margin: 0;
    display: block;
    cursor: pointer;
}
.filterbox .filtertitle:before {
    font-family: 'Font Awesome Solid';
    content: "\f054";    
    margin: 0 10px 0 0;
    float: left;
}
.filterbox .opened .filtertitle:before, .filterbox.opened .filtertitle:before {    
    content: "\f078";    
}
.filterbox li .subfiltertitle:before {
    font-family: 'Font Awesome Solid';
    font-size: 10px;
    font-weight: 400;
    content: "\f054";    
    margin: 2px 10px 0 8px;
    float: left;
}
.filterbox li .opened .subfiltertitle:before, .filterbox li.opened .subfiltertitle:before {    
    content: "\f078";    
}
.filterbox li .subfiltertitle{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.filtertitle i{
    font-size: 16px;
    margin: 0 0 0 10px;
    float: right;
}
.filterbox ul{
    list-style: none;
}
.filterbox.opened ul, .filterbox .selected ul , .filterbox.selected ul{  
    margin-top: 10px;
}

.filterbox .opened ul ul , .filterbox.opened ul ul{ 
    margin: 5px 0 5px 0;
}
.filterbox .selected ul ul, .filterbox.selected ul ul{ 
    margin: 0;
}

.filterbox ul li span{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.filterbox ul li span i{
    float: right;
    margin-left: 10px;
    font-size: 11px;
}

.filterbox ul li div{
    display: none;
}
.filterbox ul li ul li span{
    margin: 0 0 0 15px;
    font-size: 12px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.filterbox .dvForm_imagecheck{
    padding: 3px;
    margin: 0 0 1px 0;
    cursor: pointer;
}
.filterbox ul li ul .dvForm_imagecheck{
    padding: 2px;
    margin: 0 0 1px 0;
}
.filterbox.opened .halbtagefilter li, .filterbox.selected .halbtagefilter li{
    display: inline-block;
}
.filterbox .halbtagefilter div{
    width: 18px;
    height: 18px;
    border: 1px solid #e5e5e5;
}
.filterbox .dvForm_imagecheck .checked, .filterbox .dvForm_imagecheck.checked, .filterbox .dvForm_datefield .checked{
    background: #e5e5e5;
}
.filterbox .dvForm_imagecheck .unchecked, .filterbox .dvForm_imagecheck.unchecked, .filterbox .dvForm_datefield.unchecked{
    background: #ffffff;
}
.filterbox .dvForm_imagecheck .unchecked:before, .filterbox .dvForm_imagecheck.unchecked:before, .filterbox .dvForm_datefield.unchecked:before{
    display: block;
    font-family:'Font Awesome Regular';
    content: "\f0c8"; /*f096*/
    float: left;
    color: #666;
    margin-right: 4px;
    font-weight: 200;
    font-style: normal;
    width: 15px;
}
.filterbox ul li ul li .dvForm_imagecheck .unchecked:before, .filterbox ul li ul li .dvForm_imagecheck.unchecked:before, .filterbox ul li ul li .dvForm_datefield.unchecked:before,
.filterbox ul li ul li .dvForm_imagecheck .checked:before, .filterbox ul li ul li .dvForm_imagecheck.checked:before, .filterbox ul li ul li .dvForm_datefield.checked:before{
    margin-left: 15px;
}
.filterbox .dvForm_imagecheck .checked:before, .filterbox .dvForm_imagecheck.checked:before, .filterbox .dvForm_datefield.checked:before{
    display: block;
    font-family:'Font Awesome Solid';
    content: "\f14a"; /*f046*/
    float: left;
    color: #666;
    margin-right: 4px;
    font-weight: 200;
    font-style: normal;
    width: 15px;
}
.filterbox .dvForm_imagecheck .unchecked i:after, .filterbox .dvForm_imagecheck.unchecked i:after, .filterbox .dvForm_datefield.unchecked i:after{
    display: none
}
aside .box {
    padding: 10px 15px;
}
.filtertoggle{
    color:#fff;
    display: block;
    padding: 0 15px 10px 15px;
    cursor: pointer;
    display: none;
    text-transform: uppercase;
    font-size: 20px;
}
.filtertoggle i {
    font-size: 24px;
}
.time-took{
    font-size: 11px;
    margin-left: 15px;
}
.input{
    border: #e5e5e5 solid 1px;
    padding: 2px 5px;
    width: 90%; /* fallback */
    width: calc(100% - 12px);
    border-radius: 2px;
}
.inputcontainer{
    border: #e5e5e5 solid 1px;
    padding: 5px 5px;
    border-radius: 2px;
}
.input_checkbox{
    text-align: center;
    vertical-align: middle;
}
.input.ir{
    width: 70%; /* fallback */
    width: calc(100% - 40px);
}
.input.ir2{
    width: 60%; /* fallback */
    width: calc(100% - 55px);
}
.input.half{
    width: 39%;
}
.dvForm_imagecheck{
    cursor: pointer;
    &.singleline {
        span{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }
    }

}
.filtertools{
    overflow: hidden;
    margin: 0 0 0 18px;
}
.checkAll, .checkNone{
    font-size: 12px;
    float: left;
    margin-right: 10px;
    cursor: pointer;
    padding-bottom: 10px;
    
    &.btn{
        background: #CCCCCC;
        font-size:10px;
        padding: 2px 8px;
        padding-bottom: 2px;
        margin-bottom: 10px;
        margin-right: 5px;
        cursor: pointer;
        border-radius: 2px;
    }
}
.checkAll i, .checkNone i{
    margin-right: 5px;
}

.form-row{
    margin-bottom: 15px;
}
.form-row-label{
    display: inline-block;
    vertical-align: top;
}
.form-row-content{
    display: inline-block;
}
.detailrow .dvForm_imagecheck .checked:before, .detailrow .dvForm_imagecheck.checked:before{
    display: block;
    font-family:'Font Awesome Solid';
    content:"\f14a";
    font-weight: 200;
    float: left;
    color: #333;
    font-style: normal;
    width: 20px;
}
.detailrow .dvForm_imagecheck .unchecked:before, .detailrow .dvForm_imagecheck.unchecked:before{
    display: block;
    font-family:'Font Awesome Regular';
    content:"\f0c8";
    font-weight: 200;
    float: left;
    color: #333;
    font-style: normal;
    width: 20px;
}
.selectbox {
    border: #e5e5e5 solid 1px;
    padding: 0 5px;
    width: 90%;
    width: calc(100% - 12px);
    border-radius: 2px;
    overflow: hidden;
}

.selectbox select {
    background: transparent;
    -webkit-appearance: none;
    /*background-color: #ffffff;*/
    background-image: url(/dist/selectbox.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 17px) 0;
    width: calc(100% + 22px);
    height:24px;
    padding: 2px 5px;
    outline: none;
    border: none;
    border-radius: none;
}
select:focus, option:focus, select::-moz-focus-inner, option::-moz-focus-inner{
    outline: none;
    border: none;
}
select:-moz-focusring { 
    color: transparent;
    text-shadow: 0 0 0 #333; 
}
.selectbox optgroup{
    background: #cccccc;
}
.selectbox optgroup option{
    background: #ffffff;
    padding-left: 20px;
}
/*******************************************/
/************ NAVIGATION *******************/
/*******************************************/
#menubutton {
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 45px;
    display: none;
    color: #fff;
}
/*
#navigation {
    position: absolute;
    bottom: 0;
    left: 200px;
}
#navigation ul {
    padding: 0 0 0 7px;
    margin:  0;
}
#footer_nav ul {
    padding: 0;
    margin:  0;
}
#navigation ul:after, #footer_nav ul:after {
    clear: both;
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
}
#navigation ul li, #footer_nav ul li{
    list-style: none;
    float:left;
}
*/
/*
#footer_meta{
    float: right;
}
#footer_meta ul li{
    list-style: none;
    float:none;
}
#footer_meta ul li a, #footer_meta ul li a:link, #footer_meta ul li a:visited, #footer_meta ul li a:active {
    text-decoration: none;
    display: block;
    color: #ffffff;
    font-weight: 400;
    padding: 5px;
    margin-right: 30px;
    font-size: 14px;
}
#footer_bottom{
    overflow: hidden;
    margin: 20px 0;
}
#footer_bottom .shariff{
    margin: 7px 0 0 0;
}
#footer_nav ul li li {
    list-style: none;
    float:none;
}
#navigation ul li a, #navigation ul li a:link, #navigation ul li a:visited, #navigation ul li a:active {
    text-decoration: none;
    display: block;
    color: #ffffff;
    font-weight: 400;
    padding: 6px 13px 6px 13px;
    font-size: 20px;
}
#footer_nav ul li a, #footer_nav ul li a:link, #footer_nav ul li a:visited, #footer_nav ul li a:active {
    text-decoration: none;
    display: block;
    color: #ffffff;
    font-weight: 400;
    padding: 5px;
    margin-right: 30px;
    font-size: 20px;
}

#navigation ul li a.selected {
    background: #e5e5e5;
    color: #666666;
}
#navigation ul li li a, #navigation ul li li a:link, #navigation ul li li a:visited, #navigation ul li li a:active {
    padding: 5px 0 5px 0;
    font-size: 16px;
    width: 240px;
    color: #666666;
}
#footer_nav ul li li a, #footer_nav ul li li a:link, #footer_nav ul li li a:visited, #footer_nav ul li li a:active {
    padding: 5px;
    font-size: 14px;
    color: #ffffff;
}

#navigation ul li:hover a, #navigation ul li:hover a.selected {
    background: #ffffff;
    color: #666666;
    
     box-shadow: 0 10px 10px #999999;
    -moz-box-shadow: 0 10px 10px #999999;
    -webkit-box-shadow: 0 10px 10px #999999;
}
#navigation ul li li a.selected, #navigation ul li:hover li a.selected {
    background: #fff;
    font-weight: 700;
     box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
#navigation ul li:hover li a {
     box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

#navigation ul li:hover > ul {
    visibility: visible;
}
#navigation ul li ul{
    display: inline;
    visibility: hidden;
    position: absolute;
    padding:10px 0 10px 20px;
    background: #ffffff;
    
     box-shadow: 0 10px 10px #999999;
    -moz-box-shadow: 0 10px 10px #999999;
    -webkit-box-shadow: 0 10px 10px #999999;
    z-index: 1800;

}
#navigation ul li ul li{
    float: none;
}
#navigation ul li ul li a:hover{
    color: #333;
}

#footer_nav ul li a:hover, #footer_nav ul li li a:hover {
    color: #e5e5e5;
}
*/


#footer_meta{
    float: right;
}
#footer_meta ul li{
    list-style: none;
    float:none;
}
#footer_meta ul li a, #footer_meta ul li a:link, #footer_meta ul li a:visited, #footer_meta ul li a:active {
    text-decoration: none;
    display: block;
    color: #ffffff;
    font-weight: 400;
    padding: 5px;
    margin-right: 30px;
    font-size: 14px;
}
#footer_bottom{
    overflow: hidden;
    margin: 20px 0;
}
#footer_bottom .shariff{
    margin: 7px 0 0 0;
}

/*
#metanavigation {
    float: right;
}
#metanavigation ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}
#metanavigation li {
    float: left;
}
#metanavigation a {
    color: #ffffff;
    font-size: 12px;
    padding: 8px 0 7px 15px;
    display: block;
}
*/
/*
#plattformnavigation {
    float: right;
    width: 150px;
    margin: 0 0 0 30px;
}
#plattformnavigation ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}
#plattformnavigation li {
    float: left;
}
#plattformnavigation a {
    width: 30px;
    height: 30px;
    font-size: 14px;
    display: block;
}
*/
#plattformtitle{
    top: 10px;
    position: absolute;
    right: 140px;
    text-align: right;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 10px;
}
.pointer{
    cursor: pointer;
}
.message_positive{
    background:#a1d7a6;
    border: 0;
    border-left: #26A65B solid 10px;
    padding:5px;
    margin:35px 0 15px 0;
    text-align:center;
    overflow:hidden;
}
.message_negative{
    background:#faa990;
    border: 0;
    border-left:#EC644B solid 10px;
    padding:5px;
    margin:35px 0 15px 0;
    text-align:center;
    overflow:hidden;
}
.message_notice, #message_logout{
    background:#faf1a8;
    border: 0;
    border-left:#ddca30 solid 10px;
    padding:5px;
    margin:35px 0 15px 0;
    text-align:center;
    overflow:hidden;
}
.message_info{
    background:#E5E5E5;
    border: 0;
    border-left:#ccc solid 10px;
    padding:5px;
    margin:0 0 15px 0;
}
.formhint{
    font-size: 10px;
    color: #999;
    margin-top: 10px;
}
.bg_notice{
    background:#faf1a8;
    border: 0;
    border-left:#ddca30 solid 10px;
    padding:5px;
    overflow:hidden;
}
.dvForm_missed{
    background:#faf1a8;
}
footer{
    position: relative;
}
#footer_inner{
    position: relative;
}
#footer_debug{
    position: fixed;
    bottom: 0;
    background: #cc0000;
    color: #ffffff;
    padding: 10px;
    width: 100%;
}
#footer_debug h1{
    font-size: 24px;
    color: #ffffff;
    margin: 0 0 5px 0;
    padding: 0;
}
#footer_debug div{
    overflow: hidden;
}
#footer_debug div label{
    float: left;
    width: 150px;
    margin: 0 10px 0 0;
}
#footer_debug div span{
    display: block;
    overflow: hidden;
}
.nm{
    margin: 0;
}
.mt{
    margin-top: 20px;
}
.mt1{
    margin-top: 5px;
}
.mt2{
    margin-top: 10px;
}
.mt3{
    margin-top: 30px;
}
.mt4{
    margin-top: 50px;
}
.ml{
    margin-left: 20px;
}
.mb{
    margin-bottom: 20px;
}
.mb1{
    margin-bottom: 5px;
}
.mb2{
    margin-bottom: 30px;
}
.mb3{
    margin-bottom: 10px;
}
.mr{
    margin-right: 20px;
}
.mr4{
    margin-right: 5px;
}
.mr3{
    margin-right: 10px;
}
.ma{
    margin: 10px;
}
.pa{
    padding: 10px;
}
.fr{
    float: right;
}
.fl{
    float: left;
}
.fn{
    float: none;
}
.tr{
    text-align: right;
}
.tl{
    text-align: left;
}
.ib{
    display: inline-block;
}
.db{
    display: block;
}
.vc{
    vertical-align: middle;
}
.st1,.st2,.st3,.st4,.st5,.st6{
    display: inline-block;
}
.mr1{
    margin-right: 5%;
}
.mr2{
    margin-right: 10%;
}
.ml1{
    margin-left: 5%;
}
.ml2{
    margin-left: 10%;
}
.ml3{
    margin-left: 200px;
}
.ml4{
    margin-left: 1%;
}
.ml5{
    margin-left: 5px;
}
.nmt{
    margin-top: 0;
}
.npt{
    padding-top: 0;
}
.err404{
    width: 100%;
}
.w40{
    width: 40px;
    display: inline-block;
}
.grey{
    color: #cccccc;
}
.red{
    color: #cc0000;
}
.green{
    color: #009900;
}
.yellow{
    color: #E08A1E;
}
.blue{
    color: #0066cc    
}
.ndlevel, .discuss{
    font-size: 20px;
}
.close{
    float: right;
    font-size: 25px;
    line-height: 25px;
    padding: 5px;
    cursor: pointer;
    margin-top: -10px;
}
.layout-table, .layout-tiles{
    float: right;
    font-size: 24px;
    margin: 0 0 0 15px;
    color: #fff;
    cursor: pointer;
}
.total-items{
    font-size: 20px;
    margin: 0 0 0 10px;
}

#dv_tooltip{
    display: none;
    position: absolute;
    z-index: 1500;
}
#dv_tooltip div{
    padding: 5px 10px;
    background: #999;
    box-shadow: 0 5px 5px #ccc;
    -moz-box-shadow: 0 5px 5px #ccc;
    -webkit-box-shadow: 0 5px 5px #ccc;
    border-radius: 2px;
    color: #333;
    overflow: hidden;
    /*text-align: center;*/
}
#dv_tooltip p, .dvForm_imagecheck p{
    display: block;
    max-width: 300px;
}
#dv_tooltip div label , .dvForm_imagecheck label{
    width: 120px;
    display: block;
    float: left;
}
#setrating{
    display: none;
    position: absolute;
    z-index: 1501;
}
#setrating_inner{
    padding: 5px 10px;
    background: #999;
    box-shadow: 0 5px 5px #ccc;
    -moz-box-shadow: 0 5px 5px #ccc;
    -webkit-box-shadow: 0 5px 5px #ccc;
    border-radius: 2px;
    color: #333;
    overflow: hidden;
    width: 130px;
}
#setrating_inner::before {
    border-bottom: 7px solid #999;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block !important;
    position: absolute;
    left: 65px;
    top: -7px;
}
#setrating .basestars{
    font-family: Font Awesome Solid;
    font-size: 21px;
    position: relative;
    z-index: 10;
    white-space: nowrap;
    overflow: hidden;
    width: 130px;
    cursor: pointer;
    color: #fff;
    letter-spacing: 3px;
}
#setrating .basestars:before{
    content: "\f005 \f005 \f005 \f005 \f005";
}
#setrating .selectstars{
    position: absolute;
    left:0;
    top:0;
    z-index: 10;
    white-space: nowrap;
    overflow: hidden;
    width: 120px;
    cursor: pointer;
    display: inline-block;
    color:#ffbc04;
}
#setrating .selectstars:before {
    content: "\f005 \f005 \f005 \f005 \f005";
}
.pagination, .loding{
    text-align: center;
    overflow: hidden;
}
.pagination a, .loding a {
    padding: 10px 20px;
    background: #e5e5e5;
    margin-top: 20px;
    display: inline-block;
}

.loding .loader{
    text-align: center;
}
.image-box{
    width: 99%;
    &.multi{
        float: left;
        width: 132px;
        margin: 0 10px 0 0;
    }
}
.image-box a.imageupload,.image-box a.imageupload:active,.image-box a.imageupload:hover{
    width: 100%;
    text-align: center;
    padding: 6px 0; 
}
.uldl{
    margin: 0 0 5px 20px;
    font-size: 25px;
    vertical-align: middle;
    cursor: pointer;
}
.image-container{
    width: 100%;
    height: 230px;
    overflow: hidden;
    background-position: 50% 50%;
    background-size:cover;
    margin-bottom: 10px;
    margin-bottom: 5px;
    background-repeat: no-repeat;
}
.image-container.contain{
    background-size:contain;
}
.image-container-s{
    width: 100px;
    height: 100px;
    overflow: hidden;
    background-position: 50% 50%;
    background-size:cover;
    margin-bottom: 10px;
    margin:auto;
    margin-bottom: 5px;
    background-repeat: no-repeat;
}
.image-container-m{
    width: 130px;
    height: 130px;
    overflow: hidden;
    background-position: 50% 50%;
    background-size:cover;
    margin-bottom: 10px;
    margin:auto;
    margin-bottom: 5px;
    background-repeat: no-repeat;
}

.image-box .imgborder{
    border: 1px dashed #e5e5e5;
}
#img_profilbild_small.image-container{
    width: 160px;
    height: 160px;
    overflow: hidden;
    background-position: 50% 50%;
    background-size:cover;
}
.creditbilanzline{
    border-top: 1px #999999 solid;
    font-size: 1px;
    height: 1px;
}
.summe{
    font-weight: 700;
}
.small{
    font-size: 10px;
}
.favorit{
    cursor: pointer;
}
.fileupload_progress{
    width: 100%;
    background: #cccccc;
    margin: 5px 0 5px 0;
    clear: both;
    overflow: hidden;
}
.fileupload_progress div{
    color: #FFFFFF;
    text-align: right;
    padding: 5px 0;
    overflow: hidden;
}
.fileupload_progress div span{
    display: block;
    margin: 4px 10px 0 0;
}
.fileupload_progress div{
    background: #e5e5e5;
}
.list-icon{
    font-size: 22px;
}
.edit-item, .statusicon, .rechnung-icon, .add-link{
    font-size: 22px;
    position: absolute;
    right: 5px;
    top: 5px;
}
.edit-item.fa-image{
    right: 35px;
    font-size: 25px;
}
.edit-item.fa-cog{
    right: 66px;
    font-size: 25px;
}
.quickedit-item, .add-item{
    font-size: 22px;
    position: absolute;
    right: 40px;
    top: 4px;
}
.add-link{
    right: 35px;
    font-size: 25px;   
}
.check-item{
    font-size: 22px;
}
.admbar .statusicon{
    right: 40px;
}
.admbar .quickedit-item{
    right: 70px;
}


/* ui */
.ui-autocomplete.ui-front{
    z-index: 2005;
}
.pstrength-minchar{
    font-size: 12px;
}



/* crosslinks */
.crosslinks{
    margin: 20px 0 0 0;
    
    &.first{
        margin: 35px 0 0 0;
    }
}
.crossl{
    display: block;
    background-color: #cccccc;
    height: 40px;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: uppercase;
    overflow: hidden;
    margin: 0 0 15px 0;
}
.crossl span {
    display: block;
    width: 40px;
    height: 40px;
    background: #999999;
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-weight: 700;
    float: left;
    margin-right: 10px;
}
.crossl p {
    margin: 13px 0;
}

#cl_umt {
    background-color: #66cc66;
}
#cl_umt span {
    background: #669966;
}
#cl_ste{
    background-color: #66ccff;
}
#cl_ste span {
    background: #4090b9;
}
#cl_swb{
    background-color:#F1654C;
}
#cl_swb span {
    background: #C3272B;
}

#cl_saf{
    background-color: #FFB61E;
}
#cl_saf span {
    background: #E08A1E;
}
.info{
    font-size: 14px;
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; 
    
    &.first{
        margin-top: 35px;
    }
}
.line_t{
    border-top: 1px dashed #999;
    padding-top: 5px;
}
.bi{
    font-size: 22px;
}
.meeting{
    background: #e0e0e0;
}
.savestatus{
    float: right;
    height: 15px;
    width: 15px;
}
.betr-ptf{
    overflow: hidden;
}
.betr-ptf span{
    display: block;
    float: left;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: 1px solid #999999;
}
.detailrow .betr-ptf span{
    margin-bottom: 2px;
}
.radio{
    display: block;
}
.profiledelete2{
    display: none;
}
.bipstatus{
    background: #cc0000;
    float: right;
    height: 30px;
    width: 30px;
    text-align: center;
    overflow: hidden
}
.bipstatus i{
    color:#ffffff;
    padding: 5px 0;
    display: inline-block;
    font-size: 20px;
}
.bipstatus.hijack, a.button2.hijack{
    background: #ffcc00;
}
.paid{
    background: #a1d7a6;
}
.mahnung0{
    background: #ffccff;
}
.mahnung1{
    background: #ffcc00;
}
.mahnung2{
    background: #ff6633;
}
.mahnung3{
    background: #ff3333;
}
.abr_ind {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: 1px solid #999999;
}
.unterkuenftestatus1{
    background: #a1d7a6;
}
.unterkuenftestatus2{
    background: #c7f0cb;
}
.unterkuenftestatus5{
    background: #fcd7cc;
}
.unterkuenftestatus12{
    background: #f9cdc0;
}
.unterkuenftestatus15{
    background: #fbc8b8;
}
.unterkuenftestatus11{
    background: #fab8a4;
}
.unterkuenftestatus21{
    background: #f48766;
}
.unterkuenftestatus9{
    background: #f2744d;
}
.unterkuenftestatus95{
    background: #ef5e31;
}
.unterkuenftestatus99{
    background: #ef4630;
}
.endingsoon{
    background: #ffcc00;
}
.unterkuenftestatusopen2{
    color: #ff6600;
}
.unterkuenftestatusopen3{
    color: #ff0000;
}

.unt_ind {
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
    span{
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        border: 1px solid #999999;
    }
}
body.ste {
    .premium{ 
        display: none; 
    }
    .pre {
        background-color: #dcf3ff;
    }
    .largenotice{
        width: 140%;
    }
    .video{
        height: 210px;
    }
    .preview{
        max-width: 100%;
        max-height: 300px;
        margin-left: 50%;
        transform: translateX(-50%);
        border: 1px solid #cccccc;
        background: #fff;
    }
    .thumbnails{
        overflow: hidden;
        margin: 0 0 20px 0;
        text-align: right;
    }
    .thumbnail{
        display: inline-block;
        margin: 0 0 0 2px;
        width: 70px;
        height: 70px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
    }
    .thumbnail:first-of-type{
        margin: 0;
    }
}
#map-container{
    position: relative;
    
    .map-tooltip{
        position: absolute;
        background: #ccc;
        padding: 10px;
        z-index: 999999;
        display: none;
        width: 250px;
        
        top: 0;
        left: 0;
        
        h2{
            font-size: 14px;
            margin-bottom: 10px;
            width: 250px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        img{
            width: 250px;
            margin-bottom: 10px;
        }
        p{
            margin-bottom: 0;
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            span{
                display: inline-block;
                width: 60px;
                font-weight: 400;
            }
        }
        
    }
    
    &.fullmap{
        height: 100%;
        height: calc(100vh - 100px);
        width: 100%;
        
        #map-container-inner{
            height: 100%;
            width: 100%;
        }

    }
}